.container {
    text-align: center;
}

.title {
    margin-top: 50px;
    color: beige;
    font-size: 50px;
    display: flex;
    justify-content: center;
}

.reset {
    width: 200px;
    height: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    background: #1b2838;
    font-size: 26px;
    color: #66c0f4;
    margin-top: 20px;
    margin-left: 30px;
}

.boxes {
    display: flex;
    height: 180px;
    width: 180px;
    background: #1b2838;
    border: 4px solid #66c0f4;
    border-radius: 12px;
    cursor: pointer;
    margin: 10px 5px;
}

.gameBoard {
    display: flex;
    height: 600px;
    width: 564px;
    margin: auto;
}

.winBanner {
    background-color: #1b2838;
    color: #66c0f4;
    font-size: 30px;
    border-radius: 20px;
    width: 150px;
    height: 40px;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.winBanner img {
    height: 20px;
}